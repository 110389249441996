// @ts-nocheck
import {
    AnalyticsPlugin,
    AnalyticsScope,
    MxAnalytics as MyAsurionAnalytics,
    withExtra,
    withExtras,
} from '@soluto-private/myasurion-analytics';
import {
    consolePlugin,
    createFullStoryPlugin,
    createGoogleTagManager,
    createMixpanelPlugin,
    createSolutoEnterprisePlugin,
} from './helpers';
import { handleSession, startExperimentListener } from './extra-data';
import {
    hydrateBrowserIdentity,
    hydrateGoogleAnalyticsIdentity,
    hydrateSessionIdentity,
} from './identities';

import { MxAnalyticsDispatcher } from './dispatcher';

class MxAnalytics extends MyAsurionAnalytics {
    protected readonly _dispatcher: MxAnalyticsDispatcher<void>;

    constructor(
        plugins: AnalyticsPlugin[] = [
            createFullStoryPlugin(),
            createGoogleTagManager(),
            createMixpanelPlugin(),
            createSolutoEnterprisePlugin(),
        ],
        shared = false
    ) {
        super(plugins, shared);
        this._dispatcher = new MxAnalyticsDispatcher(this.writer());
    }

    protected enhancePlugins(
        plugins: AnalyticsPlugin[],
        shared: boolean
    ): AnalyticsPlugin[] {
        const isProduction = process.env.APP_ENV === 'production';
        return [...plugins, ...(!isProduction ? [consolePlugin] : [])];
    }

    protected writeEvent(eventModel: unknown): void {
        const name = [eventModel.Scope, eventModel.Name]
            .filter((x) => x)
            .join('_');
        if (!eventModel.Scope) {
            const packageName = eventModel.ExtraData.packageName.split('/');
            eventModel.Scope =
                eventModel.ExtraData.friendlyName ||
                packageName[packageName.length - 1];
        }
        eventModel.MetaData.IgnoreScope =
            eventModel.ExtraData.ignoreScope || false;
        eventModel.MetaData.SkipBuffer =
            eventModel.ExtraData.skipBuffer || false;

        const extra = {
            ...eventModel.Identities,
            ...eventModel.ExtraData,
            ...eventModel.MetaData,
            originalEventModel: eventModel,
        };
        this._client.track(name, extra);
    }

    public getDispatcher(
        scope?: AnalyticsScope | string
    ): MxAnalyticsDispatcher<void> {
        if (!scope) {
            return this._dispatcher;
        }
        if (typeof scope === 'string') {
            // backward compatibility
            return this._dispatcher.extend(withExtra('packageName', scope));
        }
        let { path, packageName, friendlyName, ignoreScope, skipBuffer } =
            scope;
        if (typeof friendlyName === 'undefined' || friendlyName === null) {
            const parsedName = packageName.split('/');
            friendlyName = parsedName[parsedName.length - 1];
        }

        if (typeof ignoreScope === 'undefined' || ignoreScope === null) {
            ignoreScope = false;
        }

        if (typeof skipBuffer === 'undefined' || skipBuffer === null) {
            skipBuffer = false;
        }

        return this._dispatcher.extend(
            withExtras({ path, packageName, friendlyName, ignoreScope })
        );
    }
}

const initAnalytics = () => {
    handleSession();
    startExperimentListener();
    hydrateBrowserIdentity();
    hydrateSessionIdentity();
    hydrateGoogleAnalyticsIdentity();
};

/**
 * @deprecated method will be deprecated in a following weeks. Please, migrate to "getDispatcher". Note, all
 * events will be dispatched only to mixpanel and only to Home (prod), HomeTest (non-prod) projects.
 */
const createDispatcher = (
    scope: AnalyticsScope | string,
    plugins?: AnalyticsPlugin[]
): MxAnalyticsDispatcher<void> => {
    initAnalytics();
    return new MxAnalytics(plugins).getDispatcher(scope);
};

const initDispatcher = (
    scope?: AnalyticsScope | string,
    plugins?: AnalyticsPlugin[]
): MxAnalyticsDispatcher<void> => {
    initAnalytics();
    return new MxAnalytics(plugins, true).getDispatcher();
};

const dispatcher = initDispatcher();
const getDispatcher = (scope: AnalyticsScope): MxAnalyticsDispatcher<void> => {
    let { path, packageName, friendlyName, ignoreScope, skipBuffer } = scope;
    if (typeof friendlyName === 'undefined' || friendlyName === null) {
        const parsedName = packageName.split('/');
        friendlyName = parsedName[parsedName.length - 1];
    }

    if (typeof ignoreScope === 'undefined' || ignoreScope === null) {
        ignoreScope = false;
    }
    if (typeof skipBuffer === 'undefined' || skipBuffer === null) {
        skipBuffer = false;
    }
    return dispatcher.extend(
        withExtras({ path, packageName, friendlyName, ignoreScope })
    );
};

export {
    MxAnalyticsDispatcher,
    AnalyticsScope,
    AnalyticsPlugin,
    MxAnalytics,
    getDispatcher,
    createDispatcher,
};
